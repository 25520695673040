import { extractUUID } from '@helper/extractUUID'
export const getFeaturedImg = (product: any) => {
  let featuredImg = {
    uuid: '',
    media_type: 'IMAGE',
  }

  if (product?.media?.length > 10 && product?.media_type?.length) {
    featuredImg = {
      uuid: product?.media,
      media_type: product?.media_type,
    }
  } else if (product?.additional_media?.length >= 1) {
    const media = product?.additional_media?.find(
      (item: any) => item?.media != null,
    )
    featuredImg = {
      uuid: media?.media,
      media_type: media?.media_type,
    }
  }
  // console.log('featuredImg before', featuredImg)

  if (featuredImg?.uuid) {
    if (
      !(
        featuredImg?.uuid?.includes('instagram') ||
        featuredImg?.media_type == 'VIDEO'
      )
    ) {
      featuredImg.uuid = extractUUID(featuredImg.uuid)
    }
  }
  // if (
  //   ['IMAGE', 'VIDEO'].includes(featuredImg?.media_type?.toLowerCase()) == false
  // ) {
  //   featuredImg.media_type = 'IMAGE'
  // }

  return featuredImg
}
