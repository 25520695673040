import {
  addItem,
  addItemWithQuantity,
  calculateItemTotals,
  calculateTotal,
  calculateTotalItems,
  calculateUniqueItems,
  Item,
  removeItem,
  removeItemOrQuantity,
  updateItem,
  UpdateItemInput,
} from './cart.utils'

export interface Metadata {
  [key: string]: any
}

type Action =
  | { type: 'ADD_ITEM_WITH_QUANTITY'; item: Item; quantity?: number }
  | { type: 'REMOVE_ITEM_OR_QUANTITY'; id: Item['id']; quantity?: number }
  | { type: 'ADD_ITEM'; id: Item['id']; item: Item }
  | { type: 'UPDATE_ITEM'; id: Item['id']; item: UpdateItemInput }
  | { type: 'REMOVE_ITEM'; id: Item['id'] }
  | { type: 'GENERATE_NEW_CART'; items: Item[]; meta?: { cartId: string } }
  | { type: 'ADD_META'; data: Metadata }
  | { type: 'RESET_CART' }
  | { type: 'SET_IS_SHIPPING_APPLIED'; data: boolean }
  | { type: 'RESET_META' }

export interface State {
  items: Item[]
  isEmpty: boolean
  totalItems: number
  totalUniqueItems: number
  total: number
  meta?: Metadata | null
  isShippingApplied: boolean
  isAllDigital: boolean
}
export const initialState: State = {
  items: [],
  isEmpty: true,
  totalItems: 0,
  totalUniqueItems: 0,
  total: 0,
  meta: null,
  isShippingApplied: false,
  isAllDigital: false,
}
export function cartReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_ITEM_WITH_QUANTITY': {
      const { item } = action
      const { quantity, minOrderLimit, cartIncrementStep } = item
      const items = addItemWithQuantity(
        state.items,
        action.item,
        action.quantity ??
          ((!quantity || quantity < 1) && minOrderLimit
            ? minOrderLimit
            : cartIncrementStep),
      )
      return generateFinalState(state, items)
    }
    case 'REMOVE_ITEM_OR_QUANTITY': {
      const items = removeItemOrQuantity(
        state.items,
        action.id,
        action.quantity,
      )
      return generateFinalState(state, items)
    }
    case 'GENERATE_NEW_CART': {
      return {
        ...generateFinalState(undefined, action.items),
        ...(action?.meta ? { meta: action.meta } : undefined),
      }
    }
    case 'ADD_ITEM': {
      const items = addItem(state.items, action.item)
      return generateFinalState(state, items)
    }
    case 'REMOVE_ITEM': {
      const items = removeItem(state.items, action.id)
      return generateFinalState(state, items)
    }
    case 'UPDATE_ITEM': {
      const items = updateItem(state.items, action.id, action.item)
      return generateFinalState(state, items)
    }
    case 'ADD_META': {
      const newState = setMeta(action.data, state)
      return newState
    }
    case 'RESET_META': {
      const newState = { ...state, meta: null }
      return newState
    }
    case 'RESET_CART':
      return initialState
    case 'SET_IS_SHIPPING_APPLIED': {
      const newState = { ...state, isShippingApplied: action.data }
      return newState
    }
    default:
      return state
  }
}

const setMeta = (data: Metadata, state: State) => {
  let finalMeta = state?.meta ?? {}
  Object.keys(data).forEach((key) => {
    finalMeta[key] = data[key]
  })
  return {
    ...state,
    meta: { ...finalMeta },
  }
}

const generateFinalState = (
  state: Partial<State> | undefined,
  items: Item[],
) => {
  const totalUniqueItems = calculateUniqueItems(items)
  return {
    ...state,
    items: calculateItemTotals(items),
    totalItems: calculateTotalItems(items),
    totalUniqueItems,
    isShippingApplied: false,
    total: calculateTotal(items),
    isEmpty: totalUniqueItems === 0,
    isAllDigital: items.every((item) => item.type === 'digital'),
  }
}
