import { Box, Spinner } from '@chakra-ui/react'
import { useModalAction } from '@components/common/modal/modal.context'
import { useStore } from '@contexts/store'
import { isEmpty } from 'lodash'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

export const Loader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <Spinner
      thickness="2px"
      speed="0.4s"
      emptyColor="gray.200"
      color="brand"
      size="xl"
    />
  </Box>
)

const GenericLayout = dynamic(
  () =>
    import('@components/layout/generic-layout').then(
      (module) => module.default,
    ),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)

const RetroLayout = dynamic(
  () =>
    import('@components/layout/retro-layout').then((module) => module.default),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)
const SereneLayout = dynamic(
  () =>
    import('@components/layout/serene-layout').then((module) => module.default),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)

const ElegantLayout = dynamic(
  () =>
    import('@components/layout/elegant-layout').then(
      (module) => module.default,
    ),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)

const GenericHeader = dynamic(
  () =>
    import('@components/layout/header/header').then((module) => module.default),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)

const StoreIsPaused = dynamic(
  () => import('@components/layout/StoreIsPaused'),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)
const StoreIsPending = dynamic(
  () => import('@components/layout/StoreIsPending'),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)

const StoreIsSuspended = dynamic(
  () => import('@components/layout/StoreIsSuspended'),
  {
    loading: () => <Loader />,
    ssr: false,
  },
)
const NotFound = dynamic(() => import('@components/layout/NotFound'), {
  loading: () => <Loader />,
  ssr: false,
})

const GenericGlobalLayout = ({ children }: LayoutProps) => {
  return (
    <GenericLayout>
      <GenericHeader />
      {children}
    </GenericLayout>
  )
}

export const Templates = {
  MINIMAL: {
    LandingPageLayout: GenericLayout,
    GlobalLayout: GenericGlobalLayout,
    Home: dynamic(
      () => import('@templates/minimal/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
  TRENDY: {
    LandingPageLayout: GenericLayout,
    GlobalLayout: GenericGlobalLayout,
    Home: dynamic(
      () => import('@templates/trendy/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
  CLASSIC: {
    LandingPageLayout: GenericLayout,
    GlobalLayout: GenericGlobalLayout,
    Home: dynamic(
      () => import('@templates/classic/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
  RETRO: {
    LandingPageLayout: RetroLayout,
    GlobalLayout: RetroLayout,
    Home: dynamic(
      () => import('@templates/retro/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
  ANTIQUE: {
    LandingPageLayout: GenericLayout,
    GlobalLayout: GenericGlobalLayout,
    Home: dynamic(
      () => import('@templates/antique/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
  SERENE: {
    LandingPageLayout: SereneLayout,
    GlobalLayout: SereneLayout,
    Home: dynamic(
      () => import('@templates/serene/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
  ELEGANT: {
    LandingPageLayout: ElegantLayout,
    GlobalLayout: ElegantLayout,
    Home: dynamic(
      () => import('@templates/elegant/index').then((module) => module.Home),
      {
        loading: () => <Loader />,
        ssr: false,
      },
    ),
  },
}

interface LayoutResponse {
  theme: keyof typeof Templates
  isLoading: boolean
  isError: any
  isStorePaused: boolean
  isStoreSuspended: boolean
  isStoreUnavailable: boolean
}

const useFindLayout = (): LayoutResponse => {
  const {
    storeData,
    shopInfo: { apiShopData, isLoading = true, isError } = {},
    storeTemplate,
  } = useStore()

  return {
    theme: storeTemplate,
    isLoading,
    isError,
    isStorePaused: storeData?.paused,
    isStoreSuspended: storeData?.status === 'suspended',
    isStoreUnavailable:
      !isLoading && isEmpty(storeData) && isEmpty(apiShopData),
  }
}

const useComingSoonModal = () => {
  const router = useRouter()
  const { openModal, closeModal } = useModalAction()
  const {
    shopInfo: { apiShopData },
    isStorePreview,
  } = useStore()

  useEffect(() => {
    // console.log('apiShopData?.status', router?.query?.preview)
    if (apiShopData?.status === 'pending' && !isStorePreview) {
      openModal('STORE_COMING_SOON', apiShopData, {
        // size: 'md',
        closeOnOverlayClick: false,
        hideCloseButton: true,
        contentStyle: {
          contentBoxProperties: {
            mb: '64px',
            mt: '100px',
          },
        },
      })
    } else {
      closeModal()
    }
  }, [apiShopData, router?.query, openModal, closeModal, isStorePreview])
}

const useDomainRedirectModal = () => {
  const { openModal } = useModalAction()
  const { isCustomDomainExpired } = useStore()

  useEffect(() => {
    if (isCustomDomainExpired) {
      openModal('DOMAIN_REDIRECT', undefined, {
        size: 'md',
        closeOnOverlayClick: false,
        hideCloseButton: true,
        isCentered: true,
        contentStyle: {
          contentBoxProperties: {
            mb: '64px',
            mt: '90px',
          },
        },
      })
    }
  }, [isCustomDomainExpired, openModal])
}

type LayoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const {
    theme,
    isLoading,
    isStorePaused,
    isStoreUnavailable,
    isStoreSuspended,
  } = useFindLayout()
  useComingSoonModal()
  useDomainRedirectModal()
  // if (isLoading || !theme) return <Loader />
  if (isStorePaused && process.env.APP_ENV !== 'development')
    return <StoreIsPaused />
  if (isStoreSuspended) return <StoreIsSuspended />
  if (isStoreUnavailable) return <NotFound />
  const Layout: any = Templates[theme]?.LandingPageLayout ?? GenericLayout

  return <Layout>{children}</Layout>
}

export const GlobalLayout: React.FC<LayoutProps> = ({ children }) => {
  const {
    theme,
    isLoading,
    isStorePaused,
    isStoreUnavailable,
    isStoreSuspended,
  } = useFindLayout()
  useComingSoonModal()
  useDomainRedirectModal()

  if (isLoading) return <Loader />
  if (isStorePaused && process.env.APP_ENV !== 'development')
    return <StoreIsPaused />
  if (isStoreSuspended) return <StoreIsSuspended />
  if (isStoreUnavailable) return <NotFound />
  const Layout: any = Templates[theme]?.GlobalLayout ?? GenericGlobalLayout

  return <Layout>{children}</Layout>
}

export const useFindHomePage = () => {
  const { storeTemplate } = useStore()
  if (storeTemplate) {
    return Templates[storeTemplate].Home
  }

  return Templates.MINIMAL.Home
}

export default Layout
