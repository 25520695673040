// ecommerce store context
import { ChakraProvider, DrawerProps, extendTheme } from '@chakra-ui/react'
import { ModalProvider } from '@components/common/modal/modal.context'
import { Templates } from '@components/layout/PageLayout'
import { CartProvider } from '@contexts/cart/cart.context'
import { useGetShopBySlug } from '@framework/get-shop-data'
import { StoreData } from '@framework/model-types/StoreData'
import { UserProfile } from '@framework/model-types/UserProfile'
import { assignIdToAddress, generateTheme } from '@helper/globalHelpers'
import useSessionStorage from '@hooks/use-session-storage'
import api from '@utils/api'
import { getCookie } from 'cookies-next'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import React, {
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import secureLocalStorage from 'react-secure-storage'

import { AnalyticsInstance } from 'analytics'
import { AnalyticsProvider } from 'use-analytics'
import configAnalytics from 'utils/getAnalytics'
import { Item } from './cart/cart.utils'
import { useAccessControl } from '@framework/use-shop-features'
import { FEATURE_KEYS } from '@utils/feature-keys'

const isProduction: boolean = process.env.APP_ENV === 'production'
const stagingUrl: string = process.env.NEXT_PUBLIC_STAGING_URL || ''

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

export interface ActiveCurrency {
  _id: string
  symbol: string
  rate: number
  code: string
}

const getUserLocalStorage = (): any => {
  const user = secureLocalStorage.getItem('user')
  if (user) return user
  if (typeof window === 'undefined') return
  const userBackup = sessionStorage.getItem('userBackup')
  return userBackup !== null ? JSON.parse(userBackup) : null
}

const setUserLocalStorage = (data: any) => {
  sessionStorage.setItem('userBackup', JSON.stringify(data))
  return secureLocalStorage.setItem('user', data)
}

const getUserToken = () => {
  const token = getUserLocalStorage()?.token
  if (token) {
    delete api.defaults.headers.Authorization
    api.defaults.headers.Authorization = `Bearer ${token}`
  }
  return token
}

const getLocalShop = () => {
  return secureLocalStorage.getItem('shop') as StoreData
}
const setLocalShop = (data: any) => {
  return secureLocalStorage.setItem('shop', data)
}

const getDefaultCurrency = () => {
  const currency = secureLocalStorage.getItem('currency') as ActiveCurrency
  // console.log('store currency', currency)
  if (currency) return currency

  const shop = getLocalShop()
  if (!shop) return null

  return {
    _id: shop.baseCurrency?._id,
    symbol: shop.baseCurrency?.symbol,
    rate: shop.baseCurrency?.exchangeRate,
    code: shop.baseCurrency?.code,
  }
}

export type UserAddress = UserProfile['user']['addresses'][number] & {
  id?: string
}

interface ShippingInfo {
  recommended: Record<string, any> | null
  providers: Record<string, any>[]
  payloadDetails: {
    cartId: string
    items: Item[]
    address: DeepPartial<UserAddress> | null
    userFullName: string
    userPhone: string
    userEmail: string
  }
}
export interface State {
  isAuthorized: boolean
  isStorePreview: boolean
  isCustomDomainExpired: boolean
  storeTemplate: StoreData['config']['storefront']['theme']
  shopInfo: { apiShopData: any; isLoading: boolean; isError: any }
  shippingInfo: ShippingInfo
  displaySidebar: boolean
  displayFilter: boolean
  displayCart: boolean
  displaySearch: boolean
  displayMobileSearch: boolean
  displayDrawer: boolean
  displayProductModal: boolean
  drawerView: string | null
  drawerOptions: Partial<DrawerProps>
  toastText: string
  isStickyheader: boolean
  storeData?: any
  activeProduct?: string | null
  activeCurrency: ActiveCurrency | null
  data: any
  stagingUrl: string
  user: DeepPartial<{
    token?: string
    is_guest: boolean
    person?: {
      _id?: string
      fullname: string
      preferredPaymentMethod: string
      email: string
      phone?: string
      whatsAppNumber?: string
      avatar?: string
      addresses?: UserAddress[]
    }
  }> | null
}

export interface StoreFunctions {
  setUserState: (data: State['user']) => void
  openDrawer: (data?: any, drawerOptions?: State['drawerOptions']) => void
  setShopData: (data?: any) => void
  setDrawerView: (view: DRAWER_VIEWS) => void
  closeDrawer: () => void
  closeSidebar: () => void
  logout: () => void
  closeMobileSearch: () => void
  closeSearch: () => void
  openSidebar: () => void
  toggleMobileSearch: () => void
  setDefaultCurrency: (currency: any) => void
  setShippingInfo: (value: SetStateAction<ShippingInfo>) => void
}

const getCookieForStore = getCookie('store-cookie') || null

const storeSummary = getCookieForStore ? JSON.parse(getCookieForStore) : null
const cookieTheme = storeSummary?.theme ?? null
const storeID = storeSummary?.id ?? null
const howFound = storeSummary?.howFound ?? null

const initialState = {
  isAuthorized: getUserToken(),
  isStorePreview: false,
  isCustomDomainExpired: false,
  stagingUrl: stagingUrl,
  storeTemplate: 'MINIMAL' as const,
  shopInfo: { apiShopData: null, isLoading: true, isError: null },
  shippingInfo: {
    recommended: null,
    providers: [],
    payloadDetails: {
      cartId: '',
      items: [],
      address: null,
      userFullName: '',
      userPhone: '',
      userEmail: '',
    },
  },
  displaySidebar: false,
  displayFilter: false,
  displayCart: false,
  displaySearch: false,
  displayMobileSearch: false,
  displayDrawer: false,
  displayProductModal: false,
  drawerView: null,
  drawerOptions: {},
  toastText: '',
  isStickyheader: false,
  storeData: getLocalShop() ?? { _id: storeID },
  activeProduct: null,
  data: undefined,
  activeCurrency: getDefaultCurrency(),
  user: getUserLocalStorage(),
}

type Action =
  | {
      type: 'SET_STORE'
      data: any
    }
  | {
      type: 'SET_AUTHORIZED'
    }
  | {
      type: 'SET_UNAUTHORIZED'
    }
  | {
      type: 'OPEN_SIDEBAR'
    }
  | {
      type: 'CLOSE_SIDEBAR'
    }
  | {
      type: 'CLOSE_SEARCH'
    }
  | {
      type: 'OPEN_MOBILE_SEARCH'
    }
  | {
      type: 'CLOSE_MOBILE_SEARCH'
    }
  | {
      type: 'OPEN_DRAWER'
      data: null
      drawerOptions?: State['drawerOptions']
    }
  | {
      type: 'CLOSE_DRAWER'
    }
  | {
      type: 'SET_DRAWER_VIEW'
      view: DRAWER_VIEWS
    }
  | {
      type: 'SET_ACTIVE_CURRENCY'
      data: any
    }
  | {
      type: 'SET_USER'
      data: State['user']
    }
  | {
      type: 'OPEN_PRODUCT_MODAL'
      value: string
    }
  | {
      type: 'CLOSE_PRODUCT_MODAL'
    }

type DRAWER_VIEWS =
  | 'CART_SIDEBAR'
  | 'MOBILE_MENU'
  | 'ORDER_DETAILS'
  | 'PRODUCT_MODAL'
  | 'CATEGORIES_DRAWER'
  | 'ADVANCED_FILTERS_DRAWER'

const StoreContext = createContext<State>(initialState)
StoreContext.displayName = 'AuthContext'

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_STORE': {
      return {
        ...state,
        storeData: action.data,
      }
    }
    case 'SET_AUTHORIZED': {
      return {
        ...state,
        isAuthorized: true,
      }
    }
    case 'SET_UNAUTHORIZED': {
      return {
        ...state,
        isAuthorized: false,
      }
    }
    case 'SET_USER': {
      return {
        ...state,
        user: action.data,
      }
    }
    case 'OPEN_SIDEBAR': {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case 'CLOSE_SIDEBAR': {
      return {
        ...state,
        displaySidebar: false,
        drawerView: null,
      }
    }
    case 'OPEN_PRODUCT_MODAL': {
      return {
        ...state,
        displayProductModal: true,
        activeProduct: action.value,
      }
    }
    case 'CLOSE_PRODUCT_MODAL': {
      return {
        ...state,
        displayProductModal: false,
        activeProduct: null,
      }
    }
    case 'CLOSE_SEARCH': {
      return {
        ...state,
        displaySearch: false,
      }
    }
    case 'OPEN_MOBILE_SEARCH': {
      return {
        ...state,
        displayMobileSearch: true,
      }
    }
    case 'CLOSE_MOBILE_SEARCH': {
      return {
        ...state,
        displayMobileSearch: false,
      }
    }
    case 'OPEN_DRAWER': {
      return {
        ...state,
        displayDrawer: true,
        displaySidebar: false,
        data: action.data,
        drawerOptions: action.drawerOptions ?? {},
      }
    }
    case 'CLOSE_DRAWER': {
      return {
        ...state,
        displayDrawer: false,
      }
    }
    case 'SET_DRAWER_VIEW': {
      return {
        ...state,
        drawerView: action.view,
      }
    }
    case 'SET_ACTIVE_CURRENCY': {
      return {
        ...state,
        activeCurrency: action.data,
      }
    }
  }
}

interface Props {
  children: React.ReactNode
  primaryColor?: string
  secondaryColor?: string
  font?: string
}

export const StoreProvider: React.FC<Props> = ({ children, ...restProps }) => {
  const router = useRouter()
  const [state, dispatch] = useReducer(uiReducer, initialState)
  const setStoreData = (data: any) => dispatch({ type: 'SET_STORE', data })
  const authorize = () => dispatch({ type: 'SET_AUTHORIZED' })
  const unauthorize = () => dispatch({ type: 'SET_UNAUTHORIZED' })
  const openSidebar = () => dispatch({ type: 'OPEN_SIDEBAR' })
  const closeSidebar = () => dispatch({ type: 'CLOSE_SIDEBAR' })

  const closeSearch = () => dispatch({ type: 'CLOSE_SEARCH' })
  const closeMobileSearch = () => dispatch({ type: 'CLOSE_MOBILE_SEARCH' })
  const toggleMobileSearch = () =>
    state.displayMobileSearch
      ? dispatch({ type: 'CLOSE_MOBILE_SEARCH' })
      : dispatch({ type: 'OPEN_MOBILE_SEARCH' })
  const openDrawer = (data?: any, drawerOptions?: State['drawerOptions']) =>
    dispatch({ type: 'OPEN_DRAWER', data, drawerOptions })
  const closeDrawer = () => dispatch({ type: 'CLOSE_DRAWER' })

  const setDrawerView = (view: DRAWER_VIEWS) =>
    dispatch({ type: 'SET_DRAWER_VIEW', view })
  const manageCurrency = (data: any) =>
    dispatch({ type: 'SET_ACTIVE_CURRENCY', data })
  const setUser = (data: State['user']) => dispatch({ type: 'SET_USER', data })

  const setUserState: StoreFunctions['setUserState'] = useCallback((data) => {
    let tempData = data
    if (
      !isEmpty(data) &&
      data?.person?.addresses &&
      data?.person?.addresses?.length > 0
    ) {
      tempData = {
        ...tempData,
        // @ts-ignore
        person: {
          ...tempData?.person,
          addresses: assignIdToAddress(data?.person?.addresses),
        },
      }
    }
    setUserLocalStorage(tempData)
    setUser(tempData)
    if (tempData?.token) {
      delete api.defaults.headers.Authorization
      api.defaults.headers.Authorization = `Bearer ${tempData?.token}`
      authorize()
    }
  }, [])

  const logout = useCallback(() => {
    setUserState(null)
    unauthorize()
  }, [setUserState])

  useEffect(() => {
    api.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (response?.data?.msg?.includes('Invalid token')) {
          logout()
        }
        return response
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error?.response?.status === 401) {
          logout()
        }
        return Promise.reject(error)
      },
    )
  }, [logout])

  const setDefaultCurrency = (currency: any) => {
    const tempCurrency = determineCurrency(currency)
    secureLocalStorage.setItem('currency', tempCurrency)
    manageCurrency(tempCurrency)
  }

  const setShopData = (data: any) => {
    setStoreData(data)
    setLocalShop(data)
    const storeFrontDefault = data?.currencies?.find(
      (currency: any) => currency.isStoreFrontDefault && !currency?.isDisabled,
    )
    if (isEmpty(state?.activeCurrency) && data?.currencies) {
      const currency = data?.currencies?.find(
        (currency: any) => currency.isBaseCurrency,
      )
      if (storeFrontDefault && !isEqual(currency, storeFrontDefault)) {
        setDefaultCurrency(storeFrontDefault)
      } else {
        setDefaultCurrency(currency)
      }
      return
    }

    if (
      !isEmpty(state.activeCurrency) &&
      data?.currencies &&
      storeFrontDefault
    ) {
      const currency = data?.currencies?.find(
        (currency: any) => currency?.currency?._id === state.activeCurrency._id,
      )
      if (!currency) {
        setDefaultCurrency(storeFrontDefault)
      }
    }
  }

  const determineCurrency = (currency: any) => {
    return {
      code: currency?.currency?.code,
      symbol: currency?.currency?.symbol,
      _id: currency?.currency?._id,
      rate: currency?.currencyRate / currency?.baseRate,
    }
  }

  const storefrontDetails = state?.storeData?.config?.storefront

  const myTheme = useMemo(
    () =>
      generateTheme({
        font: restProps?.font ?? storefrontDetails?.font ?? '',
        primaryColor:
          restProps?.primaryColor ?? storefrontDetails?.colors?.primary ?? '',
        secondaryColor:
          restProps?.secondaryColor ??
          storefrontDetails?.colors?.secondary ??
          '',
      }),
    [
      restProps?.font,
      restProps?.primaryColor,
      restProps?.secondaryColor,
      storefrontDetails?.colors?.primary,
      storefrontDetails?.colors?.secondary,
      storefrontDetails?.font,
    ],
  )

  const { hasFeature } = useAccessControl(state?.storeData?._id)

  const hasAnalytics = hasFeature(FEATURE_KEYS.ANALYTICS_PLUGINS)

  const analytics = useMemo(() => {
    const analytics = configAnalytics(
      state?.storeData,
      state?.user,
      hasAnalytics,
    )
    return analytics
  }, [state?.storeData, state?.user, hasAnalytics])

  useEffect(() => {
    const store: any = state?.storeData
    if (isEmpty(store) || !hasAnalytics) return
    const facebookPixel = store?.socials?.find(
      (x: any) => x.slug === 'facebook-pixel',
    )
    if (!facebookPixel) return
    const facebookPixelId = facebookPixel?.id
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init(facebookPixelId, null, {
          autoConfig: true,
          debug: !isProduction,
        }) // facebookPixelId
        ReactPixel.pageView()
        // console.log("ReactPixel", ReactPixel)
        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events, state?.storeData])

  useEffect(() => {
    secureLocalStorage.setItem('theme', myTheme)
  }, [myTheme])

  const theme = extendTheme({
    ...myTheme,
  })

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      /* invoke analytics function only for production */
      // console.log('isProduction', url)
      // if (isProduction){
      analytics.page()
      // analytics.page({}, {
      //   plugins: {
      //     // disable this specific identify in all plugins except googleTagManager
      //     all: false,
      //     "google-tag-manager": true
      //   }
      // });
      // }
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, analytics])

  const slugOrDomain = useMemo(() => {
    if (howFound?.value) return { ...howFound } // return the stored and hopefully cached data from cookies
    if (!isEmpty(storeID)) return { type: 'id', value: storeID }
  }, [storeID, howFound])

  // console.log('slugOrDomain', slugOrDomain)
  const { shop, isLoading, isError } = useGetShopBySlug(
    slugOrDomain,
    state?.storeData && {
      fallbackData: state?.storeData,
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    !isEmpty(shop?.data) ? setShopData(shop?.data) : setShopData(null)
    //eslint-disable-next-line
  }, [shop?.data])

  const url =
    typeof window !== 'undefined' ? new URL(window?.location.href) : undefined
  const preview = url?.searchParams?.get('preview')

  const [storePreview, setStorePreview] = useSessionStorage(
    'storePreview',
    preview,
  )

  // console.log('storePreview', storePreview)
  const storeTemplate = useMemo(() => {
    const template =
      preview ??
      storePreview ??
      cookieTheme ??
      shop?.data?.config?.storefront?.theme ??
      state?.storeData?.theme

    if (!template) {
      return 'MINIMAL'
    }

    const upperCaseTemplate = template.toUpperCase()
    return upperCaseTemplate in Templates ? upperCaseTemplate : 'MINIMAL'
  }, [
    preview,
    storePreview,
    cookieTheme,
    shop?.data?.config?.storefront?.theme,
    state?.storeData?.theme,
  ])

  useEffect(() => {
    if (!router.isReady) return
    let preview = router?.query?.preview as string | undefined
    if (!preview) return
    preview = preview?.toUpperCase()
    if (preview in Templates) {
      setStorePreview(preview)
    } else {
      setStorePreview('MINIMAL')
    }
  }, [router.isReady, router?.query?.preview, setStorePreview])

  const [shippingInfo, setShippingInfo] = useSessionStorage('shippingInfo', {
    recommended: null,
    providers: null,
  })

  const isCustomDomainExpired = useMemo(() => {
    if (howFound?.type !== 'domainName') return false
    if (shop?.data?.originalUrl === shop?.data?.url) return false
    return true
  }, [shop?.data, howFound])

  const value = React.useMemo(
    () => ({
      ...state,
      isStorePreview:
        Boolean(router.isReady && router?.query?.preview) ||
        shop?.data?.status === 'pending' ||
        Boolean(storePreview),
      isCustomDomainExpired,
      shippingInfo,
      storeTemplate,
      shopInfo: {
        apiShopData: shop?.data,
        isLoading,
        isError,
        isStoreFetched: shop?.success,
      },
      setShippingInfo,
      authorize,
      unauthorize,
      openSidebar,
      closeSidebar,
      openDrawer,
      closeDrawer,
      closeSearch,
      closeMobileSearch,
      toggleMobileSearch,
      setDrawerView,
      setShopData,
      setDefaultCurrency,
      setUserState,
      logout,
      analytics,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state,
      storeTemplate,
      router.isReady,
      router?.query?.preview,
      shop,
      isError,
      isLoading,
      shippingInfo,
      analytics,
      isCustomDomainExpired,
    ],
  )

  return (
    // @ts-ignore
    <AnalyticsProvider instance={analytics}>
      {/* @ts-ignore */}
      <CartProvider>
        {/* @ts-ignore */}
        <ModalProvider>
          <StoreContext.Provider value={value}>
            <ChakraProvider theme={theme}>{children}</ChakraProvider>
          </StoreContext.Provider>
          {/* <ProductModal /> */}
        </ModalProvider>
      </CartProvider>
    </AnalyticsProvider>
  )
}

export const useStore = () => {
  const context = React.useContext(StoreContext) as State &
    StoreFunctions & { analytics: AnalyticsInstance }
  if (context === undefined) {
    throw new Error(`useStore must be used within a StoreProvider`)
  }
  return context
}

export default StoreProvider
