import isEmpty from 'lodash/isEmpty'
import { State as CartState } from '@contexts/cart/cart.reducer'
import { State } from '@contexts/store'

export async function generateCartPayload(items: CartState['items'], user: State["user"]) {
  const cartItems = items.map((item) => {
    const { productId, name, quantity, variant } = item
    const itemObj = {
      _id: productId,
      name,
      qty: quantity,
      ...(!isEmpty(variant) ? { variant: { _id: variant._id } } : {}),
    }
    return itemObj
  })

  const payload = {
    items: cartItems,
    ...(
      user?.person?._id
        ? { user: user.person._id }
        : {
            ...(user?.person?.email ? { email: user.person.email } : {}),
            ...(user?.person?.phone ? { telephone: user.person.phone } : {}),
            ...(user?.person?.fullname ? { fullName: user.person.fullname } : {}),
          }
    ),
  };
  
  return payload
}
