import { useApi } from '@utils/api'
import { API_ENDPOINTS } from '@utils/api-endpoints'

type UseGetShopBySlugReturnType = {
  shop: any;
  isLoading: boolean;
  isError: boolean; // Or a more specific error type if applicable
};

export const useGetShopBySlug = (slug: any, options?: any): UseGetShopBySlugReturnType => {

  
  const url: string = `${API_ENDPOINTS.GET_PUBLIC_SHOP}?${slug?.type}=${slug?.value}`;
  // console.log('url in here', url);
  const { data, isError, isLoading } = useApi(url, options);
  // console.log('data in here', data, isError, isLoading);
  if (!slug?.type || !slug?.value) {
    return {
      shop: {
        data: null,
        success: false,
      },
      isLoading: false,
      isError: false, // or true, depending on how you want to handle this case
    };
  }
  return {
    shop: data,
    isLoading,
    isError,
  };
};
