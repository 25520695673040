import Axios, { AxiosError } from 'axios'
import { useEffect, useRef } from 'react'
import useSWR, { preload } from 'swr'
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite'

let fingerprint = ''

if (typeof window !== 'undefined') {
  const { ClientJS } = require('clientjs')

  const client = new ClientJS()
  fingerprint = client.getFingerprint()
  //console.log({ fingerprint })
}

const api = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Device-Fingerprint': fingerprint,
  },
})

const fetcher = async (url: any) => {
  const { data } = await api.get(url)
  return data
}

const useApi = <T = any>(url: string | null, options?: any) => {
  const hasMounted = useRef(false)
  useEffect(() => {
    hasMounted.current = true
  }, [])

  const { data, mutate, error } = useSWR<T>(
    (url?.length && !url?.includes('undefined')) && !url?.includes('null')
      ? url
      : null,
    fetcher,
    {
      ...options,
      fallbackData: hasMounted.current ? undefined : options?.fallbackData,
    },
  )
  return {
    data: data,
    isError: error ?? false,
    mutate: mutate,
    isLoading: Boolean(!error && !data && url && url?.length > 0),
    key: url,
  }
}

const useApiInfinite = <T extends { data: any }>(
  url: string | null | undefined | false,
  pageLimit = 20,
  options?: SWRInfiniteConfiguration,
) => {
  const { data, mutate, error, size, setSize, isLoading, isValidating } =
    useSWRInfinite<T, AxiosError>(
      (pageIndex, previousPageData) => {
        if (previousPageData && !previousPageData?.data?.next) {
          return null
        }
        const apiUrl = url
          ? `${url}&page=${pageIndex + 1}&limit=${pageLimit}`
          : null
        return apiUrl
      },
      fetcher,
      options,
    )
  return {
    data,
    error,
    mutate,
    size,
    setSize,
    isValidating,
    isLoading,
    key: url,
  }
}

const usePreloader = (url: string) => {
  return () => preload(url, fetcher)
}

export { fetcher, useApi, useApiInfinite, usePreloader }
export default api

// useSWRInfinite((index, prev) => `https://api.github.com/repos/reactjs/react-a11y/issues?per_page=${PAGE_SIZE}&page=${index + 1}`,
//     fetcher,
//   );
