import useWindowSize from '@hooks/use-window-size'
import React, { useMemo } from 'react'
type MODAL_VIEWS =
  | 'SIGN_UP_VIEW'
  | 'LOGIN_VIEW'
  | 'FORGET_PASSWORD'
  | 'VIEW_ACCOUNT'
  | 'PAYMENT'
  | 'ADDRESS_VIEW_AND_EDIT'
  | 'PHONE_NUMBER'
  | 'DELIVERY_VIEW'
  | 'PRODUCT_VIEW'
  | 'CATEGORY_VIEW'
  | 'ORDER_PAYMENT'
  | 'SHIPPING_POLICY'
  | 'RETURN_POLICY'
  | 'DOWNLOAD_DIGITAL_PRODUCT'
  | 'CONFIRM_ACCOUNT_EMAIL'
  | 'FIND_MY_ORDER'
  | 'STORE_COMING_SOON'
  | 'DOMAIN_REDIRECT'
  | 'ALL_POLICIES'

interface State {
  view?: MODAL_VIEWS
  data?: any
  isOpen: boolean
  modalOptions?: {
    isCentered?: boolean
    size?: string //'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'
    variant?: 'default' | 'bottom'
    closeOnOverlayClick?: boolean
    closeOnEsc?: boolean
    blockScrollOnMount?: boolean
    useInert?: boolean
    scrollBehavior?: 'inside' | 'outside'
    preserveScrollBarGap?: boolean
    returnFocusOnClose?: boolean
    returnFocusRef?: any
    autoFocus?: boolean
    initialFocusRef?: any
    finalFocusRef?: any
    hideCloseButton?: boolean
    showOverlay?: boolean
    contentStyle?: {
      overlayOptions?: {
        [key: string]: any
      }
      overLayProperties?: {
        background?: string
        opacity?: number
      }
      contentBoxProperties?: {
        [key: string]: any
      }
      contentBodyProperties?: {
        [key: string]: any
      }
    }
  }
  size: string
}
type Action =
  | {
      type: 'open'
      view?: MODAL_VIEWS
      payload?: any
      modalOptions?: State['modalOptions']
    }
  | { type: 'close' }

const initialState: State = {
  view: undefined,
  isOpen: false,
  data: undefined,
  modalOptions: {},
  size: 'md',
}

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        view: action.view,
        data: action.payload,
        modalOptions: action.modalOptions,
        isOpen: true,
      }
    case 'close':
      return {
        ...state,
        view: undefined,
        data: undefined,
        modalOptions: {},
        isOpen: false,
      }
    default:
      throw new Error('Unknown Modal Action!')
  }
}

const ModalStateContext = React.createContext<State>(initialState)
ModalStateContext.displayName = 'ModalStateContext'
const ModalActionContext = React.createContext<
  React.Dispatch<Action> | undefined
>(undefined)
ModalActionContext.displayName = 'ModalActionContext'

export const ModalProvider: React.FC = ({ children }: any) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState)
  const { width: screenWidth } = useWindowSize()

  const getSize = (width: number) => {
    switch (true) {
      case width < 640:
        return 'xs'
      case width < 768:
        return 'sm'
      case width < 1024:
        return 'md'
      case width < 1280:
        return 'lg'
      case width < 1536:
        return 'xl'
      default:
        return '2xl'
    }
  }
  const size = useMemo(() => {
    // multiple cases compare
    const width = screenWidth ?? 0
    return getSize(width)
  }, [screenWidth])

  const value = useMemo(
    () => ({
      ...state,
      size,
      getSize,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, screenWidth],
  )

  return (
    <ModalStateContext.Provider value={value}>
      <ModalActionContext.Provider value={dispatch}>
        {children}
      </ModalActionContext.Provider>
    </ModalStateContext.Provider>
  )
}

export function useModalState() {
  const context = React.useContext(ModalStateContext)
  if (context === undefined) {
    throw new Error(`useModalState must be used within a ModalProvider`)
  }
  return context
}

export function useModalAction() {
  const dispatch = React.useContext(ModalActionContext)
  if (dispatch === undefined) {
    throw new Error(`useModalAction must be used within a ModalProvider`)
  }
  return {
    openModal(
      view?: MODAL_VIEWS,
      payload?: unknown,
      modalOptions?: State['modalOptions'],
    ) {
      // console.log('openModal', view, payload, modalOptions)
      dispatch({ type: 'open', view, payload, modalOptions })
    },
    closeModal() {
      dispatch({ type: 'close' })
    },
  }
}
