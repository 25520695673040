import { enc } from 'crypto-js'
import AES from 'crypto-js/aes'
import { isEqual } from 'lodash'
import React, { SetStateAction, useCallback, useEffect } from 'react'
const secretPassphrase = 'storefrontFromQSHOPv2'
const useSessionStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const valueFromSession: string =
        window.sessionStorage.getItem(keyName) ?? ''
      const value = AES.decrypt(valueFromSession, secretPassphrase)
      if (value) {
        return JSON.parse(value.toString(enc.Utf8))
      } else {
        window.sessionStorage.setItem(keyName, JSON.stringify(defaultValue))
        return defaultValue
      }
    } catch (err) {
      return defaultValue
    }
  })

  // useEffect(() => {
  //   if (typeof window === 'undefined') return
  //   const listenStorageChange = () => {
  //     try {
  //       const valueFromSession = AES.decrypt(
  //         window.sessionStorage.getItem(keyName) ?? '',
  //         secretPassphrase,
  //       ).toString(enc.Utf8)
  //       const value = valueFromSession
  //         ? JSON.parse(valueFromSession)
  //         : valueFromSession
  //       if (!isEqual(value, storedValue)) {
  //         setStoredValue(value)
  //       }
  //     } catch (err) {
  //       console.log('err', err)
  //       return
  //     }
  //   }
  //   window.addEventListener('storage', listenStorageChange)
  //   return () => window.removeEventListener('storage', listenStorageChange)
  // }, [keyName, storedValue])

  useEffect(() => {
    try {
      const decrypt = AES.decrypt(
        window.sessionStorage.getItem(keyName) ?? '',
        secretPassphrase,
      ).toString(enc.Utf8)

      const valueFromSession = decrypt ? JSON.parse(decrypt) : decrypt
      if (isEqual(valueFromSession, storedValue)) {
        return
      }
      const value = AES.encrypt(
        JSON.stringify(storedValue),
        secretPassphrase,
      ).toString()
      window.sessionStorage.setItem(keyName, value)
    } catch (err) {
      return
    }
  }, [storedValue, keyName])

  const setValue = useCallback((newValue: SetStateAction<any>) => {
    setStoredValue(newValue)
  }, [])

  return [storedValue, setValue] as const
}

export default useSessionStorage
