import { useCart } from '@contexts/cart/cart.context'
import { Item } from '@contexts/cart/cart.utils'
import { useStore } from '@contexts/store'
import { CartInfo } from '@framework/model-types/CartInfo'
import { generateCartPayload } from '@hooks/generate-cart-payload'
import api, { useApi } from '@utils/api'
import { API_ENDPOINTS } from '@utils/api-endpoints'
import { useCallback, useRef } from 'react'

interface CreateCartProps {
  shopId: string
  payload: any
}

interface CartEditProps {
  shopId: string
  payload?: any
  cartId: string
}

export const createCart = async ({ shopId, payload }: CreateCartProps) => {
  try {
    const url: string = `${API_ENDPOINTS.CART(shopId)}`
    const { data } = await api.post(url, payload)
    return { cart: { ...data } }
  } catch (error) {
    const data = {
      data: null,
      msg: 'cart not created',
      success: false,
    }
    return { cart: { ...data } }
  }
}

interface DeleteCartProps {
  shopId: string
  cartId: string
}
interface DeleteCartItemProps {
  shopId: string
  cartId: string
  cartItemId: string
}

export const deleteCart = async ({ shopId, cartId }: DeleteCartProps) => {
  try {
    const url = `${API_ENDPOINTS.DELETE_CART(shopId, cartId)}`
    const { data } = await api.delete(url)
    return { result: { ...data } }
  } catch (error) {
    const data = {
      data: null,
      msg: 'Cart deletion failed',
      success: false,
    }
    return { result: { ...data } }
  }
}

export const clearCartItem = async ({
  shopId,
  cartId,
  cartItemId,
}: DeleteCartItemProps) => {
  try {
    const url = `${API_ENDPOINTS.DELETE_CART_ITEM(shopId, cartId, cartItemId)}`
    const { data } = await api.delete(url)
    return { result: { ...data } }
  } catch (error) {
    const data = {
      data: null,
      msg: 'Cart item deletion failed',
      success: false,
    }
    return { result: { ...data } }
  }
}

export const useCartManagementInApi = () => {
  const {
    resetCart,
    meta,
    clearItemFromCart,
    items,
    addMeta,
    isShippingApplied,
  } = useCart()
  const { storeData: shop, user } = useStore()
  const cartId = meta?.cartId
  const { cartInfo, mutate: mutateCart } = useGetCart(shop?._id, cartId)

  const resetCartInApi = useCallback(() => {
    resetCart()
    if (cartId) {
      deleteCart({
        shopId: shop?._id,
        cartId: cartId,
      })
    }
  }, [cartId, resetCart, shop?._id])

  const previousItems = useRef(items)

  const createOrUpdateCartInApi = useCallback(
    async (onSuccess?: () => {}, onFailure?: () => {}) => {
      const payload = await generateCartPayload(items, user)
      if (payload?.items?.length === 0) {
        resetCartInApi()
      }
      if (payload?.items?.length > 0 && !isShippingApplied) {
        let response: any = {}
        if (cartId) {
          if (previousItems.current.length <= items.length) {
            response = await alterCart({
              shopId: shop?._id,
              cartId: cartId,
              payload: payload,
            })
            mutateCart()
          }
          previousItems.current = items
        } else {
          response = await createCart({ shopId: shop?._id, payload: payload })
        }
        const { cart } = response
        if (cart?.success) {
          const newcartId = cart?.data?._id
          if (!cartId || cartId !== newcartId) {
            addMeta({ cartId: newcartId })
          }
          if (onSuccess) onSuccess()
        } else {
          if (onFailure) onFailure()
        }
      }
    },
    [
      cartId,
      isShippingApplied,
      items,
      shop,
      user,
      addMeta,
      resetCartInApi,
      mutateCart,
    ],
  )

  const clearCartItemInApi = useCallback(
    async (cartItem: Item) => {
      clearItemFromCart(cartItem?.id)
      const cartItemId = cartInfo?.items?.find(
        (item: any) =>
          item?._id === cartItem?.productId &&
          item?.variant?._id === cartItem?.variant?._id,
      )?.cartItemId

      if (cartId && cartItemId) {
        const { result } = await clearCartItem({
          shopId: shop?._id,
          cartId,
          cartItemId,
        })
        if (result?.success) {
          mutateCart()
        }
      }
    },
    [cartId, cartInfo?.items, clearItemFromCart, mutateCart, shop?._id],
  )

  return {
    resetCartInApi,
    clearCartItemInApi,
    createOrUpdateCartInApi,
  }
}

export const alterCart = async ({ shopId, cartId, payload }: CartEditProps) => {
  try {
    const url: string = `${API_ENDPOINTS.ALTER_CART(shopId, cartId)}`
    const { data } = await api.patch(url, payload)
    return { cart: { ...data } }
  } catch (error: any) {
    const errorMsg: string = error?.response?.data?.msg ?? ''
    if (errorMsg.includes('does not exist')) {
      return await createCart({ shopId, payload })
    }
    const data = {
      data: errorMsg.includes('does not exist') ? 'create' : null,
      msg: error?.response?.data?.msg,
      success: false,
    }
    return { cart: { ...data } }
  }
}

export const useGetCart = (shopId: string, cartId: string) => {
  const url: string = `${API_ENDPOINTS.CART(shopId)}?id=${cartId}`
  const { data, isError, isLoading, mutate } = useApi(cartId ? url : '')

  return {
    cartInfo: data?.data as CartInfo,
    isLoading,
    isError,
    mutate,
    key: url,
  }
}
