import { useApi } from '@utils/api'
import { API_ENDPOINTS } from '@utils/api-endpoints'
import { FEATURE_KEYS } from '@utils/feature-keys'
import { useCallback, useMemo } from 'react'
import { APIResponseDataType } from './model-types/APIResponseDataType'
import { ShopFeature } from './model-types/ShopFeature'

export const useShopFeatures = (shopId: string | undefined) => {
  const url = shopId ? API_ENDPOINTS.GET_FEATURES(shopId) : null
  return useApi<APIResponseDataType<ShopFeature[]>>(url)
}

export const useAccessControl = (shopId: string | undefined) => {
  const { data: shopFeatures } = useShopFeatures(shopId)
  const shopfeatureKeys = useMemo(() => {
    return new Set(shopFeatures?.data?.map((feature) => feature?.addon?.key))
  }, [shopFeatures?.data])

  const hasFeature = useCallback(
    (key: (typeof FEATURE_KEYS)[keyof typeof FEATURE_KEYS]) => {
      return shopfeatureKeys.has(key)
    },
    [shopfeatureKeys],
  )

  return {
    hasFeature,
  }
}
