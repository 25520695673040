import { Item } from '@contexts/cart/cart.utils'
import { Product as CustomerOrderProduct } from '@framework/model-types/CustomerOrders'
import { Product } from '@framework/model-types/Product'
import { extractUUID } from '@helper/extractUUID'
import { getFeaturedImg } from '@helper/getFeaturedImg'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

export function generateCartItem(
  product: {
    _id: string
    name: string
    slug: string
    price: Product['price']
    sale_price: Product['sale_price']
    stock: Product['stock']
    type: Product['type']
    sku: Product['sku']
    order: Product['order']
  },
  variant: any,
) {
  const {
    _id: id,
    name,
    slug,
    price,
    sale_price,
    stock,
    type,
    sku,
    order,
  } = product || {}
  const maxOrderLimit = order?.limit?.max
  const minOrderLimit = order?.limit?.min
  const step =
    order?.limit?.useMinValueAsStep && minOrderLimit ? minOrderLimit : 1
  const featuredImg = getFeaturedImg(product)
  if (!isEmpty(variant)) {
    const variantMedia = !isEmpty(variant?.media)
      ? {
          uuid:
            variant?.media?.includes('instagram') ||
            variant?.media_type?.toLowerCase() == 'video'
              ? variant?.media
              : extractUUID(variant?.media),
          media_type:
            variant?.media?.includes('instagram') &&
            variant?.media?.includes('.mp4') &&
            variant?.media_type !== 'VIDEO'
              ? 'VIDEO'
              : variant?.media_type,
        }
      : featuredImg
    return {
      id: variant._id,
      productId: id,
      name: `${name}`,
      slug,
      type,
      stock: variant.stock,
      maxOrderLimit: maxOrderLimit ?? 0,
      minOrderLimit: minOrderLimit ?? 0,
      cartIncrementStep: step,
      price: variant.sale_price ? variant.sale_price : variant.price,
      fullPrice: variant.price,
      image: variantMedia,
      variant: variant,
    } as Item
  }
  return {
    id,
    productId: id,
    name,
    slug,
    type,
    sku,
    stock: stock,
    maxOrderLimit: maxOrderLimit ?? 0,
    minOrderLimit: minOrderLimit ?? 0,
    cartIncrementStep: step,
    price: sale_price ? sale_price : price,
    fullPrice: price,
    image: featuredImg,
    variant: null,
  } as Item
}

export function generateCartItemName(name: string, attributes: object) {
  if (!isEmpty(attributes)) {
    const sortedAttributes = orderBy(attributes)
    return `${name.toLowerCase()} - ${sortedAttributes.join(', ')}`
  }
  return name.toLowerCase()
}
