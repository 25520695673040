import googleAnalytics from '@analytics/google-analytics'
import googleTagManager from '@analytics/google-tag-manager'
import mixpanelPlugin from '@analytics/mixpanel'
import facebookPixel from './facebookPixelAnalytics'
import { State as StoreState } from '@contexts/store'
import Analytics from 'analytics'
import isEmpty from 'lodash/isEmpty'

const IS_BROWSER = typeof window !== 'undefined'
const enabledAnalytics = IS_BROWSER //&& (process.env.APP_ENV !== 'development' || process?.env?.ENABLE_ANALYTICS)
const googleTagManagerPlugin: any = {
  containerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_ID,
  preview: process.env.NEXT_PUBLIC_GOOGLE_TAG_PREVIEW_ENV,
  auth: process.env.NEXT_PUBLIC_GOOGLE_TAG_AUTH,
}

Object.keys(googleTagManagerPlugin).forEach((key) => {
  if (!googleTagManagerPlugin[key]) {
    delete googleTagManagerPlugin[key]
  }
})
// console.log('googleTagManagerPlugin', googleTagManagerPlugin)
// default QShop analytics
const plugins = [googleTagManager(googleTagManagerPlugin)]

const storePlugins = ['google-tag-manager', 'mixpanel', 'facebook-pixel']

const configAnalytics = (store: any, user: StoreState['user'], hasAnalytics: boolean) => {
  let userPlugins = []
  let finalPlugins = []
  if (!isEmpty(store?.socials)) {
    // const storePlugins = ['google-tag-manager', 'google-analytics', 'mixpanel', 'facebook-pixel']
    const enabledPlugins = store?.socials?.filter(
      (x: any) => storePlugins.includes(x.slug) && x?.id,
    ) ?? []

    for (let plugin of enabledPlugins) {
      switch (plugin.slug) {
        case 'google-tag-manager':
          {
            const storeGTM = Object.assign(
              {},
              googleTagManager({ containerId: plugin?.id }),
              {
                name: 'store-google-tag-manager',
              },
            )
            userPlugins.push(storeGTM)
          }
          break
        case 'google-analytics':
          userPlugins.push(
            googleAnalytics({
              measurementIds: plugin?.id,
            }),
          )
          break
        case 'mixpanel':
          userPlugins.push(
            mixpanelPlugin({
              token: plugin?.id,
            }),
          )
          break
        case 'facebook-pixel':
          userPlugins.push(
            facebookPixel({
              pixelId: plugin?.id,
              enabled: true,
              debug: process.env.NODE_ENV === 'development',
            }),
          )
          break;
        default:
          break
      }
    }
  }

  finalPlugins =
    userPlugins?.length && hasAnalytics
      ? [...plugins, ...userPlugins]
      : [...plugins]

  const analyticsInstance = Analytics({
    app: 'qshop storefront',
    version: '2',
    debug: process.env.APP_ENV === 'development',
    plugins: [...(enabledAnalytics ? finalPlugins : [])],
  })

  let analyticsPayload: Record<string, string | undefined> = {}

  if (store?._id) {
    analyticsPayload = {
      ...analyticsPayload,
      storeId: store?._id,
      storeName: store?.name,
      storeEmail: store?.email,
      slug: store?.slug,
    }
  }

  if (user?.person) {
    analyticsPayload = {
      ...analyticsPayload,
      $name: user?.person?.fullname,
      name: user?.person?.fullname,
      $phone: user?.person?.phone,
      phone: user?.person?.phone,
      $email: user?.person?.email,
      email: user?.person?.email,
    }
  }

  if (user?.person?.email) {
    analyticsInstance?.identify(user?.person?.email, analyticsPayload)
  }

  return analyticsInstance
}

export default configAnalytics
