import { useStore } from '@contexts/store'
import { useGetCart } from '@framework/cart/manage-cart'
import { generateCartItem } from '@hooks/generate-cart-item'
import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { Metadata, State, cartReducer, initialState } from './cart.reducer'
import { Item, getItem, inStock, remaininginStock } from './cart.utils'

interface CartProviderState extends State {
  addItemToCart: (item: Item, quantity?: number) => void
  removeItemFromCart: (id: Item['id'], quantity?: number) => void
  clearItemFromCart: (id: Item['id']) => void
  getItemFromCart: (id: Item['id']) => any | undefined
  isInCart: (id: Item['id']) => boolean
  isInStock: (id: Item['id']) => boolean
  resetCart: () => void
  generateNewCart: (items: Item[], meta?: { cartId: string }) => void
  setIsShippingApplied: (isShippingApplied: boolean) => void
  addMeta: (data: Metadata) => void
  resetMeta: () => void
  getStockBalance: (
    id: Item['id'],
    product?: { selectedQuantity: number; currentStock: number },
  ) => number
}
export const cartContext = React.createContext<CartProviderState | undefined>(
  undefined,
)

cartContext.displayName = 'CartContext'

export const useCart = () => {
  const context = React.useContext(cartContext)
  if (context === undefined) {
    throw new Error(`useCart must be used within a CartProvider`)
  }
  return context
}

export const CartProvider: React.FC = (props: any) => {
  const initialCart = secureLocalStorage.getItem('qshop-cart') ?? initialState
  const [savedCart, setSavedCart] = useState<any>(initialCart)

  const [state, dispatch] = React.useReducer(cartReducer, savedCart)
  React.useEffect(() => {
    setSavedCart(state)
    secureLocalStorage.setItem('qshop-cart', state)
  }, [state])

  const router = useRouter()
  const { storeData: shop } = useStore()

  const cartParam = router?.query?.cart

  const cartIdParam =
    (Array.isArray(cartParam) ? cartParam[0] : cartParam) || ''

  const { cartInfo } = useGetCart(shop?._id, cartIdParam)

  const addItemToCart = useCallback(
    (item: Item, quantity?: number) =>
      dispatch({ type: 'ADD_ITEM_WITH_QUANTITY', item, quantity }),
    [],
  )
  const removeItemFromCart = useCallback(
    (id: Item['id'], quantity?: number) =>
      dispatch({ type: 'REMOVE_ITEM_OR_QUANTITY', id, quantity }),
    [],
  )
  const generateNewCart: CartProviderState['generateNewCart'] = useCallback(
    (items, meta) => dispatch({ type: 'GENERATE_NEW_CART', items, meta }),
    [],
  )
  const clearItemFromCart = useCallback(
    (id: Item['id']) => dispatch({ type: 'REMOVE_ITEM', id }),
    [],
  )
  const isInCart = useCallback(
    (id: Item['id']) => !!getItem(state.items, id),
    [state.items],
  )

  const getItemFromCart = useCallback(
    (id: Item['id']) => getItem(state.items, id),
    [state.items],
  )
  const isInStock = useCallback(
    (id: Item['id']) => inStock(state.items, id),
    [state.items],
  )
  const resetCart = useCallback(() => dispatch({ type: 'RESET_CART' }), [])
  const getStockBalance = useCallback(
    (
      id: Item['id'],
      product?: { selectedQuantity: number; currentStock: number },
    ) => remaininginStock(state.items, id, product),
    [state.items],
  )
  const addMeta = useCallback(
    (data: Metadata) => dispatch({ type: 'ADD_META', data }),
    [],
  )
  const resetMeta = useCallback(() => dispatch({ type: 'RESET_META' }), [])

  const setIsShippingApplied = useCallback((data: boolean) => {
    dispatch({ type: 'SET_IS_SHIPPING_APPLIED', data })
  }, [])


  useEffect(() => {
    if (cartInfo?.items?.length) {
      generateNewCart(
        cartInfo?.items.map(({ variant, qty, ...item }) => ({
          ...generateCartItem(item, variant),
          quantity: qty,
        })),
        {
          cartId: cartIdParam,
        },
      )
      const newQuery = { ...router.query }
      delete newQuery['cart']
      router.replace(
        { pathname: router?.pathname, query: newQuery },
        undefined,
        {
          scroll: false,
        },
      )
    }
  }, [cartIdParam, cartInfo?.items, generateNewCart, router])

  const value = React.useMemo(
    () => ({
      ...state,
      addItemToCart,
      removeItemFromCart,
      clearItemFromCart,
      getItemFromCart,
      isInCart,
      isInStock,
      resetCart,
      getStockBalance,
      addMeta,
      resetMeta,
      setIsShippingApplied,
      generateNewCart,
    }),
    [
      getItemFromCart,
      isInCart,
      isInStock,
      getStockBalance,
      clearItemFromCart,
      resetCart,
      addMeta,
      generateNewCart,
      setIsShippingApplied,
      removeItemFromCart,
      state,
      resetMeta,
      addItemToCart,
    ],
  )
  return <cartContext.Provider value={value} {...props} />
}
