import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { ChakraStylesConfig } from 'chakra-react-select'
import insane from 'insane'
import { isEmpty } from 'lodash'
import {
  Inter,
  Montserrat,
  Nunito,
  Open_Sans,
  Poppins,
  Raleway,
} from 'next/font/google'
import { BiCloudDownload } from 'react-icons/bi'
import { FaHandHolding } from 'react-icons/fa'
import { MdOutlineLocalShipping } from 'react-icons/md'
import validateColor from 'validate-color'
import Values from 'values.js'

const poppins = Poppins({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  // preload: true,
})

const nunito = Nunito({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  // preload: true,
})

const montserrat = Montserrat({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  // preload: true,
})

const openSans = Open_Sans({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  // preload: true,
})

const raleway = Raleway({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  // preload: true,
})

const inter = Inter({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  // preload: true,
})

const nextFonts = {
  Poppins: poppins,
  Nunito: nunito,
  Montserrat: montserrat,
  ['Open Sans']: openSans,
  Raleway: raleway,
  Inter: inter,
}

export const SocialIcons: any = {
  facebook: '/assets/icons/oauth/facebook.svg',
  'facebook-pixel': '/assets/icons/oauth/facebook.svg',
  twitter: '/assets/icons/oauth/twitter.svg',
  google: '/assets/icons/oauth/google.svg',
  apple: '/assets/icons/oauth/apple.svg',
  youtube: '/assets/icons/oauth/youtube.svg',
  instagram: '/assets/icons/oauth/instagram.svg',
  messenger: '/assets/icons/oauth/messenger.svg',
  whatsapp: '/assets/icons/oauth/whatsapp.svg',
  linkedin: '/assets/icons/oauth/linkedin.svg',
  pinterest: '/assets/icons/oauth/pinterest.svg',
  reddit: '/assets/icons/oauth/reddit.svg',
  snapchat: '/assets/icons/oauth/snapchat.svg',
  tiktok: '/assets/icons/oauth/tiktok.svg',
  vk: '/assets/icons/oauth/vk.svg',
  wechat: '/assets/icons/oauth/wechat.svg',
}

export const productType = (type: string) => {
  switch (type) {
    case 'physical':
      return {
        type: 'Physical',
        icon: <MdOutlineLocalShipping />,
      }
    case 'services':
      return {
        type: 'Services',
        icon: <FaHandHolding />,
      }
    case 'digital':
      return {
        type: 'Digital',
        icon: <BiCloudDownload />,
      }
    default:
      return {
        type: 'Physical',
        icon: <MdOutlineLocalShipping />,
      }
  }
}

export const getSocialIcon = (provider: string) => {
  return SocialIcons[provider]
}

export const validateName = (label: string, name: string) => {
  let output = { value: name, error: false, message: '' }
  if (name.length > 30) {
    output.error = true
    output.message = `${label} cannot exceed 30 characters`
  }
  if (name && !/^([a-zA-Z\s]+)$/.test(name)) {
    output.error = true
    output.message = `${label} can only contain letters.`
  }

  return output
}

export const validatePhone = (label: string, phone: string) => {
  let output = { value: phone, error: false, message: '' }

  if (phone && !/^-?\d+\.?\d*$/.test(phone)) {
    output.error = true
    output.message = `${label} can only contain numbers.`
  }

  return output
}

export const validateEmail = (email: string) => {
  let output = { valid: false, message: '' }
  //@ts-ignore
  //eslint-no-control-regex
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
  if (!emailRegex.test(email)) {
    output.valid = false
    output.message = 'You must provide a valid email address.'
  } else {
    output.valid = true
  }

  return output
}

export function uniqueArray(items: any) {
  const set = new Set()
  return items?.filter((item: any) => {
    const isDuplicate = set.has(item.id)
    set.add(item.id)
    return !isDuplicate
  })
}

export function uniqueArrayOnKey<T extends object>(items: T[], key: keyof T) {
  const set = new Set()
  return Array.isArray(items)
    ? items?.filter((item: any) => {
        const isDuplicate = set.has(item[key])
        set.add(item[key])
        return !isDuplicate
      })
    : []
}

export const uriEncodeObject = (filter: Record<string, string> | undefined) => {
  if (!filter) return ''

  const queryString = Object.keys(filter)
    .map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(filter[key])
    })
    .join('&')
  return `${queryString}`
}

export const getUnique = (arr: any, comp: any) => {
  const unique = arr
    .map((e: any) => e[comp])
    .map((e: any, i: any, final: any) => final.indexOf(e) === i && i)
    .filter((e: any) => arr[e])
    .map((e: any) => arr[e])

  return unique
}

export const jsonDeDuplication = (json: any) => {
  return [...new Set(json.map((o: any) => JSON.stringify(o)))]
    .sort((a: any, b: any) => a - b)
    .map((i: any) => JSON.parse(i))
}

export const isColor = (strColor: string): boolean => {
  if (isEmpty(strColor)) return false
  try {
    const isColor = validateColor(strColor)
    return isColor
  } catch (err: unknown) {
    return false
  }
}

const $startColor = cssVar('skeleton-start-color')
const $endColor = cssVar('skeleton-end-color')

const baseStyle = defineStyle({
  _light: {
    [$startColor.variable]: '#F6F6F6',
    [$endColor.variable]: '#F3F2F2',
  },
})

const skeletonTheme = defineStyleConfig({
  baseStyle,
})

export const defaultTheme = {
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          // borderRadius: '0.5',
          boxShadow: '0 0 0 0',
        },
      },
      sizes: {
        xs: { dialog: { maxW: '320px', p: '0.25rem' } },
        sm: { dialog: { maxW: '640px', p: '0.25rem' } },
        md: { dialog: { maxW: '600px', p: '0.25rem' } },
        lg: { dialog: { maxW: '940px', p: 0 } },
        xl: { dialog: { maxW: '1180px', p: '0.75rem' } },
        '2xl': { dialog: { maxW: '1360px', p: '0.75rem' } },
      },
    },
    Skeleton: skeletonTheme,
  },
  fonts: {
    heading: `Inter,  sans-serif`,
    body: `Inter, sans-serif`,
    color: '#333',
  },
  colors: {
    transparent: 'transparent',
    black: '#000',
    sereneBlack: '#231F1E',
    inputFieldOutline: '#EFF1F7',
    white: '#fff',
    brand: '#ff9900',
    alternate: '#0099e6',
    grayIconColor: '#8C969F',
    placeholderColor: '#9EA1AB',
    elegantGray: '#21325B1A',
    elegantGrayText: '#677788',
    elegantBlack: '#1E2022',
    danger: '#EF2206',
    success: '#06DB41',
    orange: '#FF9900',
    // red: '#ff0000',
    primary: {
      100: '#ffebcc',
      200: '#ffc266',
      300: '#ff9900',
      400: '#995c00',
      500: '#331f00',
    },
    secondary: {
      100: '#e6f7ff',
      200: '#99e6ff',
      300: '#4dc2ff',
      400: '#0099e6',
      500: '#006699',
    },
    initialColorMode: 'white',
    // ...
  },
}

//chakraSelectStyles
export const chakraSelectStyles: ChakraStylesConfig<any> = {
  control: (provided) => ({
    ...provided,
    fontSize: '14px',
    fontWeight: '700',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    bg: 'transparent',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
}

// generate the theme based on config from the user

export const generateTheme = ({
  primaryColor = '#F8991B',
  secondaryColor = '#000000',
  font,
}: {
  primaryColor: string | undefined
  secondaryColor: string | undefined
  font: string | undefined
}) => {
  const fontFamily = font && font in nextFonts
    ? nextFonts[font as keyof typeof nextFonts]?.style?.fontFamily
    : inter?.style?.fontFamily

  const storeTheme = {
    ...defaultTheme,
    fonts: {
      ...defaultTheme?.fonts,
      heading: `${fontFamily}, Inter,  sans-serif`,
      body: `${fontFamily}, Inter,  sans-serif`,
    },
  }

  if (!primaryColor && !secondaryColor) return storeTheme
  let finalTheme = storeTheme
  const builder = new Values()

  const myTheme: any = {
    colors: {
      brand: '',
      alternate: '',
      primary: {},
      secondary: {},
    },
  }
  if (isColor(primaryColor)) {
    const hues = builder.setColor(primaryColor).all(25)
    hues.forEach((color: any, index: number) => {
      myTheme.colors.brand = primaryColor
      myTheme.colors.primary[`${index + 1}00`] = color.hexString()
    })
  }
  if (isColor(secondaryColor)) {
    const hues = builder.setColor(secondaryColor).all(25)
    hues.forEach((color: any, index: number) => {
      myTheme.colors.alternate = secondaryColor
      myTheme.colors.secondary[`${index + 1}00`] = color.hexString()
    })
  }
  if (isColor(myTheme?.colors?.brand)) {
    finalTheme = {
      ...finalTheme,
      colors: {
        ...finalTheme.colors,
        ...myTheme.colors,
      },
      // initialColorMode: 'white',
    }
  }
  return finalTheme
}

export const Sanitized = ({ html }: any) => (
  <div
    dangerouslySetInnerHTML={{
      __html: insane(html),
    }}
  />
)

export const generateHashFromString = (str: string) => {
  let hash = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

export const isHTMLStringEmpty = (html: string | undefined): boolean => {
  if (isEmpty(html) || !html) {
    return true
  }

  return html?.replace(/<[^<>^]+?>/g, '')?.trim()?.length === 0
}

export const getHash = () =>
  typeof window !== 'undefined'
    ? decodeURIComponent(window.location.hash.replace('#', ''))
    : undefined

export function assignIdToAddress(address: any) {
  if (isEmpty(address)) return address
  return address.map((item: any) => {
    if (item?._id) {
      item.id = item._id
      return item
    }
    if (item?.id) {
      return item
    }
    const id = generateHashFromString(item?.address)
    item.id = id
    return item
  })
}

export const allEqual = (arr: any) => arr.every((v: any) => v === arr[0])

export const bytesToSize = (bytes: number) => {
  var sizes = ['Byte', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  let i = Math.floor(Math.log(bytes) / Math.log(1024))
  i = parseInt(i.toString())
  return (
    parseFloat((bytes / Math.pow(1024, i)).toString()).toFixed(2) +
    ' ' +
    sizes[i]
  )
}
