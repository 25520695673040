type tokenOrDigitalProductsParam = {shopId: string, orderId: string, code?: string, email?: string, token?:string}

export const API_ENDPOINTS = {
    GET_PUBLIC_SHOP: '/shops/public/',
    GET_PUBLIC_SHOP_HEAD: '/shops/public/head',
    GET_SHOP_PRODUCTS: (shopId : string) => `/shops/${shopId}/products`,
    GET_SHOP_TAGS: (shopId : string) => `/shops/${shopId}/tags`,
    GET_SHOP_FILTERS: (shopId : string) => `/shops/${shopId}/products/filters`,
    GET_SHOP_DISCOUNTS: (shopId : string) => `/shops/${shopId}/coupons/public?sort=-created`,
    GET_SINGLE_PRODUCT: (shopId : string, productIdOrSlug: string) => `/shops/${shopId}/products/${productIdOrSlug}`,
    GET_PRODUCT_CATEGORIES: (shopId: string) => `/shops/${shopId}/categories`,
    GET_PAYMENT_OPTIONS: (shopId: string) => `/shops/${shopId}/paymentmethods`,
    GET_ORDER_PAYMENT_INSTRUCTION: (shopId: string, orderId: string, methodKey: string) => `/shops/${shopId}/orders/${orderId}/payments/${methodKey}`,
    GEONAMES_NEARBY: (lat: any, lon: any) => `/locations/geodata?longitude=${lon}&latitude=${lat}`,
    ALTER_CART: (shopId: string, cartId: string) => `/shops/${shopId}/cart/${cartId}`,
    DELETE_CART: (shopId: string, cartId: string) => `/shops/${shopId}/cart/${cartId}`,
    DELETE_CART_ITEM: (shopId: string, cartId: string, itemId: string) => `/shops/${shopId}/cart/${cartId}/${itemId}`,
    CART: (shopId: string) => `/shops/${shopId}/cart`,
    ADD_DISCOUNT: (shopId: string, applyOn : string, cartId: string) => `/shops/${shopId}/applydiscount/${applyOn}/${cartId}`,
    REMOVE_DISCOUNT: (shopId: string, appliedOn : string, cartId: string, discountId: string) => `shops/${shopId}/discount/${appliedOn}/${cartId}/?id=${discountId}`,
    GET_SHIPPING_RATES: (shopId: string, cartId: string, deliveryType: string, locationId?: string) => `/shops/${shopId}/delivery/rates?cart=${cartId}&type=${deliveryType}&location=${locationId}`,
    APPLY_SHIPPING_RATE: (shopId: string, cartId: string) => `/shops/${shopId}/applyshipping/cart/${cartId}`,
    CREATE_ORDER: (shopId: string) => `/shops/${shopId}/orders`,
    CREATE_CUSTOMER : `/auth/storefront/register`,
    GET_ONE_ORDER: (shopId: string, orderId: string) => `/shops/${shopId}/orders/${orderId}`, 
    GET_CUSTOMER_ORDERS: (shopId: string, userId: string) => `/shops/${shopId}/customers/${userId}/orders`,
    LOGIN: '/auth/storefront/login',
    REGISTER: (shopId: string) => `/shops/${shopId}/users/customers`,
    FORGOT_PASSWORD:  `/auth/storefront/forgot-password`,
    RENEW_TOKEN: `auth/token`,
    GET_DELIVERY_OPTIONS: (shopId: string, cartId: string, deliveryType: string) => `/shops/${shopId}/delivery/rates?cart=${cartId}&type=${deliveryType}`,
    APPLY_DELIVERY_RATE: (shopId: string, targetId: string, applyon: string) => `/shops/${shopId}/applyshipping/${applyon}/${targetId}`,
    SEARCH_PRODUCTS: (shopId: string, query: string) => `/shops/${shopId}/products?search=${query}`,
    GET_SHOP_LOCATIONS: (shopId: string) => `/shops/${shopId}/locations`,
    MANAGE_USER: (shopId: string, userId: string) => `/shops/${shopId}/customers/${userId}`,
    USER_ADDRESS: (userid: string) => `/users/${userid}/address`,
    CHANGE_USER_PASSWORD: () => `auth/storefront/password`,
    ORDERS: (shopId: string, userId: string) => `/shops/${shopId}/users/${userId}`, // placeholder
    WISHLIST: (shopId: string, userId: string) => `/shops/${shopId}/customers/${userId}/wishlist`, 
    GET_SHOP_POLICIES: (shopId: string) => `/shops/${shopId}/policies`,
    CONTACT_US: (shopId:string) => `/shops/${shopId}/contactus`,
    PRODUCT_REVIEW: (shopId: string, productId: string) => `/shops/${shopId}/products/${productId}/reviews`,
    ADD_REVIEW: (shopId: string, productId: string, userId: string) => `/shops/${shopId}/customers/${userId}/products/${productId}/reviews`,
    EDIT_REVIEW: (shopId: string, productId: string, reviewId: string, userId: string) => `/shops/${shopId}/customers/${userId}/products/${productId}/reviews/${reviewId}`,
    OTP: `/security/otp`,
    VERIFY_OTP: `/security/otp/verify`,
    GET_TOKEN_OR_DIGITAL_PRODUCTS: ({shopId, orderId, code, email, token}: tokenOrDigitalProductsParam) => `shops/${shopId}/orders/${orderId}/digital-products?code=${code || ""}&email=${email || ""}&token=${token || ""}`,
    DOWNLOAD_DIGITAL_PRODUCT_INTERNAL_API: (shopId: string, orderId: string, token: string, productId: string, variantId?: string) => `/api/download/?shopId=${shopId || ""}&orderId=${orderId || ""}&productId=${productId || ""}&token=${token || ""}&variantId=${variantId || ""}`,
    INCREMENT_DOWNLOAD_COUNT_ENDPOINT: (shopId: string, orderId: string) => `/shops/${shopId}/orders/${orderId}/download-count`,
    GET_COUNTRIES: `/locations/countries`,
    GET_STATES: (countryId: string) => `/locations/countries/${countryId}/states`,
    GET_CITIES: (stateId: string) => `/locations/states/${stateId}/cities`,
    GET_GUIDES: (shopId:string) => `/shops/${shopId}/product/guides`,
    GET_FEATURES: (shopId:string) => `/shops/${shopId}/features`,
  };
  