export const extractUUID = (url : string, extract: boolean = true) : any => {
    // eslint-disable-next-line no-useless-escape
    const uuid = url?.match(/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g);
    if (extract) {
      if (uuid && uuid.length > 0) {
        return uuid[0];
      } else {
        return "";
      }
    } else {
      return uuid;
    }
  };