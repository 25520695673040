import '@styles/globals.css'
import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import StoreProvider from '@contexts/store'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import 'overlayscrollbars/overlayscrollbars.css'

import dynamic from 'next/dynamic'
import App from 'next/app'
const FloatingChat = dynamic(
  () => import('@components/common/floating-whatsapp'),
  { ssr: false },
)

type AppOwnProps = {
  primaryColor: string
  secondaryColor: string
  font: string
}

function MyApp({
  Component,
  pageProps,
  primaryColor,
  secondaryColor,
  font,
}: AppProps & AppOwnProps) {
  // @ts-ignore
  const Layout = Component.Layout || (({ children }: any) => <>{children}</>)
  return (
    <>
      <StoreProvider {...{ primaryColor, secondaryColor, font }}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
        <ToastContainer
          style={{ marginBottom: '20px' }}
          autoClose={2000}
          position="bottom-right"
        />
        <FloatingChat />
      </StoreProvider>
    </>
  )
}

MyApp.getInitialProps = async (
  context: AppContext,
): Promise<AppOwnProps & AppInitialProps> => {
  const storeDetailsHeader = context?.ctx?.req?.headers?.['x-store-details']
  const storeDetails = storeDetailsHeader ? JSON.parse(storeDetailsHeader as string) : null

  const ctx = await App.getInitialProps(context)

  return {
    ...ctx,
    primaryColor: storeDetails?.primaryColor,
    secondaryColor: storeDetails?.secondaryColor,
    font: storeDetails?.font,
  }
}

export default MyApp
