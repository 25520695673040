// import dynamic from 'next/dynamic';
type Config = {
    pixelId: string;
    enabled?: boolean;
    debug: boolean;
};

let fb: any;
let fbLoaded = false;

// import fb from 'react-facebook-pixel';
// const fb = dynamic(() => import('react-facebook-pixel'), {
//     ssr: false,
//     loading: () => null,
// });



export default function facebookPixel(config: Config) {
    return {
        name: 'facebook-pixel',
        initialize: async () => {
            const { pixelId, debug } = config;
            if (typeof window !== 'undefined') {
                await import('react-facebook-pixel')
                .then((module) => (fb = module.default))
                .then(() => {
                  if (!fbLoaded) {
                    fb.init(pixelId, {
                      autoConfig: true,
                      debug: debug,
                    })
                    fbLoaded = true
                  }
                })
            }
        },
        
        page: (): void => {
            if (fbLoaded) {
                // console.log("facebook page", fb)
                fb.pageView();
            }
        },

        track: ({ payload }: { payload: { event: string; properties: any } }) => {
            if (fbLoaded) {
                // console.log("facebook track", payload)
                fb.track(payload.event, payload.properties);
            }
        },

        loaded: (): boolean => {
            return fbLoaded;
        },
    };
}
